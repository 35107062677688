import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/views/layout/layout.vue';
const routes = [
    {
        path: '/layout',
        // redirect: '/',
        component: Layout,
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
            },
            {
                path: '/business',
                name: 'Business',
                component: () => import(/* webpackChunkName: "Business" */ '../views/business/index.vue'),
            },
            {
                path: '/about',
                name: 'About',
                component: () => import(/* webpackChunkName: "About" */ '../views/About.vue'),
            },
            {
                path: '/address',
                name: 'Address',
                component: () => import(/* webpackChunkName: "Address" */ '../views/address/Address.vue'),
            },
            {
                path: '/message',
                name: 'Message',
                component: () => import(/* webpackChunkName: "Message" */ '../views/message/Message.vue'),
            },
            {
                path: '/404',
                name: '404',
                component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'NotFound',
                component: () => import(/* webpackChunkName: "NotFound" */ '../views/404.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(),
    // history: createWebHashHistory(),
    routes,
});
const nameMap = {
    Home: '首页',
    Business: '业务',
    Address: '联系我们',
    Message: '在线联系',
};
router.beforeEach((to, from) => {
    window.saTrack('open', {
        firstStage: to.name,
    });
});
router.beforeResolve(() => {
    window.scrollTo({
        top: 0,
    });
});
export default router;
