import { createApp } from 'vue';
import App from './App.vue';
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import iconImport from '@/tools/iconImport';
import '@/assets/font.css';
import router from './router';
// 全局注册
const app = createApp(App);
app.use(ArcoVue).use(iconImport).use(router).mount('#app');
app.config.globalProperties.$saTrack = window.saTrack;
